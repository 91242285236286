<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header class="grey white--text mb-4">
                {{ title }}
                <template v-slot:actions>
                    <v-icon color="white">
                        $expand
                    </v-icon>
                </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <slot></slot>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
    },
};
</script>