<template>
    <v-dialog v-model="showForm" fullscreen persistent hide-overlay transition="dialog-bottom-transition" @keydown.esc="closeFormScreen">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeFormScreen">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Histórico</v-toolbar-title>
            </v-toolbar>
            <v-container grid-list-xl fluid>
                <v-alert border="left" colored-border type="info" elevation="5" v-if="data">
                    <v-row>
                        <v-col cols="12">
                            <h2>Informações</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <strong>Identificador: </strong>{{ id }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" v-if="data.charger">
                            <strong>Carregador: </strong>{{ data.charger.name }}
                        </v-col>
                        <v-col cols="12" sm="6" v-if="data.plug">
                            <strong>Plug: </strong>{{ data.plug }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <strong>Carro conectado: </strong>{{ data.dateTime }}
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Valor: </strong> R$ {{ data.paymentValue }}
                        </v-col>
                    </v-row>
                    <v-row v-if="data.car">
                        <v-col cols="12" sm="6">
                            <strong>Consumo: </strong>{{ data.car.consumption }} kWh
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Potência Máxima: </strong>{{ data.car.maximumPower }} W
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Minutos Carregando: </strong>{{ data.car.minutesCharger }} m
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Minutos Conectado: </strong>{{ data.car.minutesConnected }} m
                        </v-col>
                        <v-col cols="12" sm="6" v-if="data.car.percent">
                            <strong>Porcentagem: </strong>{{ data.car.percent }}
                        </v-col>
                        <v-col cols="12" sm="6" v-if="data.car.nfc">
                            <strong>NFC: </strong>{{ data.car.nfc }}
                        </v-col>
                        <v-col cols="12" sm="6" v-if="data.car.idUser">
                            <strong>Usuário: </strong>{{ data.car.idUser }}
                        </v-col>
                        <v-col cols="12" sm="6" v-if="data.idPayment">
                            <strong>Pagamento: </strong>{{ data.idPayment }}
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Status: </strong>{{ data.status }}
                        </v-col>
                    </v-row>
                </v-alert>
            </v-container>
        </v-card>
        <ConfirmModal ref="confirm" />
    </v-dialog>
</template>

<script>
import { mask } from "vue-the-mask";
import ConfirmModal from "@/components/core/ConfirmModal";
// import ExpandPanelSimple from "@/components/core/ExpandPanelSimple.vue";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    components: { ConfirmModal, },

    props: {
        id: {
            type: Number,
        },
    },

    data() {
        return {
            rules,
            data: null,
            showForm: true,
            valueToReturn: null,
        }
    },

    watch: {
        id: {
            immediate: true,
            handler(newValue) {
                this.showForm = newValue != undefined;
                this.get();
            },
        },
    },

    mounted() {
        this.get();
    },

    methods: {
        closeFormScreen() {
            this.showForm = false;
            this.$emit("close");
        },

        openFormScreen() {
            this.showForm = true;
        },

        get() {
            if (this.id) {
                this.$http
                    .get(`api/v3/history/${this.id}`)
                    .then((result) => {
                        this.data = result;
                    })
                    .catch(() => {
                        this.data = null;
                    });
            } else {
                this.data = null;
            }
        },
    },
};
</script>