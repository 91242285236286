<template>
    <v-card color="white" :height="items.length > 0 ? 300 : 140" class="elevation-24">
        <v-card-title class="black--text">
            <v-badge color="red" :content="items ? items.length : '-'" inline>
                Notificações
            </v-badge>
            <v-spacer></v-spacer>
            <v-btn style="width: 50%" class="error" x-small dark @click="recognizeAll" v-if="items.length > 0"> Reconhecer todos</v-btn>
        </v-card-title>
        <v-card-text>
            <v-flex xs12>
                <v-progress-linear progress color="primary" :value="timerProgress" />
            </v-flex>
            <v-layout wrap class="my-0">
                <v-flex xs4>
                    <v-switch v-model="filter" color="error" label="CRITICAL" value="CRITICAL" @change="update" :disabled="loading" inset hide-details x-small />
                </v-flex>
                <v-flex xs4>
                    <v-switch v-model="filter" color="warning" label="ALERT" value="ALERT" @change="update" :disabled="loading" inset hide-details x-small />
                </v-flex>
                <v-flex xs4>
                    <v-switch v-model="filter" color="info" label="INFO" value="INFO" @change="update" :disabled="loading" inset hide-details x-small />
                </v-flex>
            </v-layout>

            <v-data-table :headers="headers" :items="items" class="elevation-2" hide-default-footer :items-per-page.sync="page" v-if="items.length > 0">
                <template v-slot:[`item.type`]="{ item }">
                    <v-chip v-if="item.type == 'INFO'" class="ma-2" color="info" outlined> <v-icon left> info </v-icon> Info </v-chip>
                    <v-chip v-else-if="item.type == 'ALERT'" class="ma-2" color="warning" outlined> <v-icon left> warning </v-icon> Alerta </v-chip>
                    <v-chip v-else class="ma-2" color="error" outlined> <v-icon left> priority_high </v-icon> Crítico </v-chip>
                </template>
                <template v-slot:[`item.id`]="{ item }">
                    <v-btn v-if="item.id" style="width: 50%" class="warning text--secondary" x-small dark @click="recognize(item)"> Reconhecer </v-btn>

                    <v-btn v-if="item.data && item.data.payment" style="width: 50%" class="info" x-small dark @click="idPayment = item.data.payment"> Pagamento </v-btn>
                    <v-btn v-if="item.data && item.data.history" style="width: 50%" class="info" x-small dark @click="idHistory = item.data.history"> Histórico </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <ConfirmModal ref="confirm" />
        <PaymentAction :id="idPayment" @close="idPayment = undefined" />
        <HistoryView :id="idHistory" @close="idHistory = undefined" />
    </v-card>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import PaymentAction from "@/components/shared/PaymentAction";
import HistoryView from "./HistoryView";

export default {
    components: { ConfirmModal, PaymentAction, HistoryView },

    data() {
        return {
            page: -1,
            items: [],
            timer: null,
            timerProgress: 100,
            idPayment: undefined,
            idHistory: undefined,
            loading: true,
            filter: ['CRITICAL'],
            headers: [
                { text: "-", value: "id", align: "right", sortable: false },
                { text: "Tipo", value: "type", align: "center", sortable: false },
                { text: "Mensagem", value: "data.message", sortable: false },
            ],
        };
    },

    created() {
        this.update();
        this.timer = setInterval(this.timerTick, 300);
    },

    methods: {
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
            }
        },
        update() {
            this.timerProgress = 100;
            if (this.filter.length > 0) {
                this.loading = true;
                this.$http
                    .get(`api/v3/notifier?filter=${this.filter}`, { showLoading: false })
                    .then((result) => {
                        this.items = result;
                    }).catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar notificações.");
                    }).finally(() => this.loading = false);
            } else {
                this.items = [];
            }
        },
        recognize(item) {
            this.$refs.confirm
                .open("Deseja realmente reconhecer essa notificação?", item.data.message)
                .then((confirm) => {
                    if (confirm) {
                        this.$http
                            .delete("api/v3/notifier", { id: item.id })
                            .then(() => {
                                this.update();
                            }).catch((error) => {
                                this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao deletar notificação.");
                            });
                    }
                });
        },
        recognizeAll() {
            if (this.filter.length > 0) {
                this.$refs.confirm
                    .open("Deseja realmente reconhecer todas as notificação?", "Operação não poderá ser desfeita.")
                    .then((confirm) => {
                        if (confirm) {
                            this.$http
                                .delete(`api/v3/notifier?filter=${this.filter}`)
                                .then(() => {
                                    this.update();
                                }).catch((error) => {
                                    this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao deletar notificação.");
                                });
                        }
                    });
            } else {
                this.$eventHub.$emit("msgError", "Seleciona pelo menos um nível.");
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
}
</script>
<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}
</style>
